import React from 'react';
import {graphql, navigate} from 'gatsby'
import _ from 'lodash';
import LS from '../components/LocalStorage';

import Layout from '../components/Layout';
import {divide,floor} from '../components/utils';

const CartProduct=({product, removeProduct})=>(
    <div className="table-row flexbox-space-between">
      <div className="product-data left-side flexbox-space-between">
        <a href="#" className="remove-item" onClick={()=>{removeProduct(product)}}>
          <img src="/images/icon-delete.svg" alt="Remove item" />
        </a>
        <a href={"/product/" + product.product_link_name} className="image-container">
          <img src={product.product_images[0]} alt="Product image" />
        </a>
        <a href={"/product/" + product.product_link_name} className="product-details">
          <strong>{product.product_name}</strong>
          <small>{product.number_of_owners} {product.type_of_group}</small>
        </a>
      </div>
      <div className="pricing">
        <div className="price-per-person">
          {product.currency} {divide(product.product_price, product.number_of_owners) } <img src="/images/icon-user.svg" alt="Per person icon" />
        </div>
        <div className="full-price">
          {product.currency} {product.product_price}
        </div>
      </div>
    </div>
)

class StatusPageTemplate extends React.Component{
    constructor(props){
        super(props);

        let products = LS.getItems('products', []);
        this.state = {
            products: products,
            total_price: products.reduce((a, product) => a + product.product_price, 0),
            total_price_per_person: products.reduce((a, product) => a + product.product_price/product.number_of_owners, 0)
        };
        this.content = this.props.data.markdownRemark.frontmatter;

        this.removeProductFromList = this.removeProductFromList.bind(this);
    }

    removeProductFromList(product_to_remove){
        let product_index = _.findIndex(this.state.products, product_to_remove);
        let temp_products = _.clone(this.state.products);
        temp_products.splice(product_index, 1);

        LS.removeItem('products', product_to_remove);

        if(!temp_products.length){
            navigate('/#shop');

        }else {
            this.setState({products: temp_products});
            this.setState({total_price: temp_products.reduce((a, product) => a + product.product_price, 0)});
            this.setState({total_price_per_person: temp_products.reduce((a, product) => a + product.product_price / product.number_of_owners, 0)});
        }
    }

    render(){
        if(!this.state.products.length){
            return null;
        }

        return (
            <Layout>
                <section className="main-section gray">
                    <div className="container">
                        <a href="/" className="back-link"><img
                            src="/images/icon-arrow-left.svg"/>{this.content.main_page_link_label}</a>
                    </div>
                    <div className="container">
                        <div className="shopping-cart-table">
                            <div className="table-row table-heading flexbox-space-between">
                                <h2 className="left-side">{this.content.table_heading}</h2>
                                <div className="pricing">
                                    <span className="thead price-per-person">{this.content.price_per_person_label}</span>
                                    <span className="thead full-price">{this.content.full_price_label}</span>
                                </div>
                            </div>

                            {
                                this.state.products.map((product, index)=>(
                                    <CartProduct product={product} key={index} removeProduct={this.removeProductFromList}/>
                                ))
                            }


                            <div className="table-row flexbox-space-between table-footer">
                                <div className="left-side">
                                    {this.content.total_label}
                                </div>

                                <div className="pricing">
                                    <div className="price-per-person">
                                        {this.state.products[0].currency} {floor(this.state.total_price_per_person)}
                                        <img src="/images/icon-user.svg" alt="Per person icon"/>
                                    </div>
                                    <div className="full-price">
                                        {this.state.products[0].currency} {floor(this.state.total_price)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flexbox-space-between">
                            <a href="/checkout" className="cta big">{this.content.link_to_checkout_label}</a>
                        </div>
                    </div>
                </section>

            </Layout>
        )
    }
};

export default StatusPageTemplate;

export const CartQuery = graphql`
  query CartQuery($id: String!) {  
     markdownRemark(id: { eq: $id }) {
      frontmatter {
        main_page_link_label
        table_heading
        price_per_person_label
        full_price_label
        link_to_checkout_label
        total_label
      }
    }    
  }
`